import { apiConfig, apiConfigCloudinary } from "./apiConfig";
import { getCookie } from "utils/cookieUtils";

const getAllNews = async () => {
  try {
    const token = getCookie("token_auth");
    if (!token) {
      throw new Error("No token found");
    }

    const response = await apiConfig.get("/news", {
      headers: { Authorization: token },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching get all news:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const getNewsById = async (id) => {
  try {
    const token = getCookie("token_auth");
    if (!token) {
      throw new Error("No token found");
    }

    const response = await apiConfig.get(`/news/new/${id}`, {
      headers: { Authorization: token },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching get all news:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const postCreate = async (payload) => {
  try {
    const token = getCookie("token_auth");
    if (!token) {
      throw new Error("No token found");
    }

    const response = await apiConfig.post("news/create", payload, {
      headers: { Authorization: token },
    });

    return response.data;
  } catch (error) {
    console.error("Error create news:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const deleteNew = async (id, id_img) => {
  try {
    const token = getCookie("token_auth");
    if (!token) {
      throw new Error("No token found");
    }

    const response = await apiConfig.delete(`/news/delete/${id}`, {
      headers: { Authorization: token },
    });

    await apiConfigCloudinary.delete(`/delete/${id_img}`);

    return response.data;
  } catch (error) {
    console.error("Error create news:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const patchNew = async (payload) => {
  try {
    const token = getCookie("token_auth");
    if (!token) {
      throw new Error("No token found");
    }

    const response = await apiConfig.patch(
      `/news/update/${payload.id}`,
      payload,
      {
        headers: { Authorization: token },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error create news:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const getPromotionsNews = async () => {
  try {
    const token = getCookie("token_auth");
    if (!token) {
      throw new Error("No token found");
    }

    const response = await apiConfig.get("/news/promociones", {
      headers: { Authorization: token },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching get getPromotionsNews:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};
export {
  getAllNews,
  getNewsById,
  postCreate,
  patchNew,
  deleteNew,
  getPromotionsNews,
};
