import React, { useEffect, useState } from "react";
import { GetTickets } from "../../service/ticket";

const MyTicket = () => {
  const [tickets, setTickets] = useState([]);
  const username = localStorage.getItem("name");

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const filteredTickets = await GetTickets();
        const userTickets = filteredTickets.filter(
          (objeto) => objeto.usuario === username
        );
        setTickets(userTickets);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTickets();
  }, [username]);

  return (
    <div className="container">
      <h1 className="text-title ">Tickets</h1>
      <hr />

      <div className="row mt-4">
        {tickets.length > 0 ? (
          <>
            {tickets?.map((ticket) => (
              <div className="card-redemptions" key={ticket.id}>
                <img
                  src={
                    ticket.estado === "en ejecucion"
                      ? "/images/technical-support.svg"
                      : "/images/wait.svg"
                  }
                  className="img"
                  alt="img_oferts"
                />
                <div className="textBox">
                  <div className="textContent">
                    <p className="title">{ticket.tipo}</p>
                  </div>
                  <p className="p"> Descripción: {ticket.descripcion}</p>
                  <p className="p estado">Estado: {ticket.estado}</p>
                  <p className="p ">Solución: {ticket.solucion}</p>
                  <span className="span">Solicitado por: {ticket.usuario}</span>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="card-redemptions">
            <img src="/images/opc1.png" className="img" alt="img_oferts" />
            <div className="textBox">
              <div className="textContent">
                <p className="title">Grupo Alianza Colombia</p>
              </div>
              <p className="p m-3"> Aún no has creado tickets</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyTicket;
