import React, { useState } from "react";
import AddNews from "components/news/AddNews";
import OpcNews from "components/news/OpcNews";

const DashboardCreateNews = () => {
  const [showView, setshowView] = useState(true);

  return (
    <section className="container">
      <button
        className={`${showView ? "categoryTicket active" : "categoryTicket"}`}
        onClick={() => setshowView(true)}
      >
        Crear Noticia/ Noticia interna
      </button>
      <button
        className={`${!showView ? "categoryTicket active" : "categoryTicket"}`}
        onClick={() => setshowView(false)}
      >
        Editar Noticia
      </button>

      {showView ? <AddNews /> : <OpcNews />}
    </section>
  );
};

export default DashboardCreateNews;
