import React, { useEffect, useState } from "react";
import HistoryTicket from "components/ticket/HistoryTicket";
import StatsTickets from "components/ticket/StatsTickets";
import { GetTickets } from "service/ticket";

const DashboardResourcesHumans = () => {
  const [tickets, setTickets] = useState([]);
  const [actionSupport, setActionSupport] = useState(false);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await GetTickets();
        // Filtramos directamente después de obtener la respuesta
        const ticketsFiltrados = response.filter(
          (ticket) => ticket.area === "Talento Humano"
        );
        setTickets(ticketsFiltrados);
      } catch (error) {
        console.log("ERROR: no se pudo obtener el listado de tickets");
      }
    };
    fetchTickets();
  }, [actionSupport]);

  return (
    <section className="container">
      <StatsTickets userTickets={tickets} typeUser={"support"} />
      <HistoryTicket
        userTickets={tickets}
        typeUser={"support"}
        action={setActionSupport}
        value={actionSupport}
      />
    </section>
  );
};

export default DashboardResourcesHumans;
