import React from "react";
import { ReactComponent as Print } from "assets/icons/Imprimir.svg";
import { ReactComponent as See } from "assets/icons/Ver.svg";
import { ReactComponent as Delete } from "assets/icons/Eliminar.svg";
import { ReactComponent as Edit } from "assets/icons/Editar.svg";
import CustomModal from "common/CustomModal";
import { useModalGac } from "hooks/useModalGac";

const OptionsTableTickets = ({ ticket }) => {
  const [isOpenModal, openModal, closeModal] = useModalGac(false);

  const openModalHandler = () => {
    openModal();
  };

  return (
    <>
      <section className="sectionOptions">
        <Print className="iconOption" onClick={openModalHandler} />
        <See className="iconOption" onClick={openModalHandler} />
        <Delete className="iconOption" title="Opción no disponible" />
        <Edit className="iconOption" title="Opción no disponible" />
      </section>
      <CustomModal isOpen={isOpenModal} closeModal={closeModal}>
        <section className="containerTicketView">
          <article className="headerTicketView">
            <img
              src="/logo-large.png"
              alt="logo Grupo Alianza Colombia"
              width={"230px"}
              height={"61px"}
            />
            <div>
              <h2 className="textMainTicketView">Ticktet</h2>
              <h2 className="textValueTicketView">{ticket?.id}</h2>
            </div>
            <div>
              <p className="textMainTicketView">Nombre:</p>
              <span className="textValueTicketView">{ticket?.usuario}</span>
            </div>
            <div>
              <p className="textMainTicketView">Estado:</p>
              <span className="textValueTicketView">{ticket?.estado}</span>
            </div>
            <div>
              <p className="textMainTicketView">Fecha de Creación:</p>
              <span className="textValueTicketView">
                {new Date(ticket?.fecha_creacion).toLocaleString()}
              </span>
            </div>
            <div>
              <p className="textMainTicketView">Fecha de Solución:</p>
              <span className="textValueTicketView">
                {new Date(ticket?.fecha_solucion).toLocaleString()}
              </span>
            </div>
            <div>
              <p className="textMainTicketView">Solución:</p>
              <span className="textValueTicketView">{ticket?.solucion}</span>
            </div>
            <div>
              <p className="textMainTicketView">Tipo:</p>
              <span className="textValueTicketView">{ticket?.tipo}</span>
            </div>
            <div>
              <p className="textMainTicketView">Descripción:</p>
              <span className="textValueTicketView">{ticket?.descripcion}</span>
            </div>
            <div>
              <p className="textMainTicketView">Archivo solución:</p>
              {ticket?.adjunto_solucion && (
                <a
                  href={ticket?.adjunto_solucion}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="textValueTicketView"
                >
                  <span className="textValueTicketView">Archivo</span>
                </a>
              )}
            </div>
          </article>

          <button
            className="btn-login mt-4"
            onClick={() => window.print()}
            style={{
              width: "fit-content",
              padding: "0 5%",
            }}
          >
            Imprimir
          </button>
        </section>
      </CustomModal>
    </>
  );
};

export default OptionsTableTickets;
