import React, { useEffect, useState } from "react";
import StatsTickets from "components/ticket/StatsTickets";
import HistoryTicket from "components/ticket/HistoryTicket";
import { GetTickets } from "service/ticket";

const DashboardAdminTickets = () => {
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await GetTickets();
        setTickets(response);
      } catch (error) {}
    };
    fetchTickets();
  }, []);

  return (
    <section className="container">
      <StatsTickets userTickets={tickets} typeUser={"support"} />
      <HistoryTicket
        userTickets={tickets}
        // typeUser={"support"}
      />
    </section>
  );
};

export default DashboardAdminTickets;
