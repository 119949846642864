import React from "react";
import TextEditor from "../TextEditor";

const AddNews = () => {
  return (
    <>
      <h1 className="text-center text-title">Sección de noticias</h1>
      <section>
        <TextEditor></TextEditor>
      </section>
    </>
  );
};

export default AddNews;
