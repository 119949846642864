import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { authService } from "service/auth";
import { useAuth } from "context/AuthContext";
import { getCookie, setCookie } from "utils/cookieUtils";
import welcome from "assets/icons/welcome.svg";
import "styles/loginStyles.css";

const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = getCookie("token_auth");
  const { setIsAuthenticated } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const handleAuth = handleSubmit(async (payload) => {
    try {
      const { token } = await authService(payload);
      const expirationDate = new Date();
      expirationDate.setMinutes(expirationDate.getMinutes() + 40);

      setCookie("token_auth", `Bearer ${token}`, {
        path: "/",
        expires: expirationDate,
        secure: true,
        sameSite: "Strict",
      });
      setIsAuthenticated(true); // Actualiza el estado de autenticación
      navigate("/profile");
    } catch (error) {
      toast.error(
        "Usuario o contraseña incorrectos. Por favor, inténtelo de nuevo."
      );
    }
  });

  useEffect(() => {
    if (token && location.pathname === "/") {
      navigate("/profile");
    }
  }, [token, location.pathname, navigate]);

  return (
    <section className="loginContainer">
      <article className="centrar">
        <img
          className="mt-5"
          src={welcome}
          alt="imagen bienvenida intranet"
          width={800}
        />
      </article>

      <article className="centrar">
        {errors?.usuario && (
          <div className="errorForm">
            <span className="">{errors?.usuario.message}</span>
          </div>
        )}
      </article>
      <article className="centrar">
        {errors?.password && (
          <span className="errorForm">{errors?.password.message}</span>
        )}
      </article>
      <article className="rowLogin">
        <div className="circleLogin">
          <img src="/gac.png" alt="" />
        </div>
        <article className="shapeLogin">
          <form onSubmit={handleAuth} className="contentForm">
            <div>
              <input
                type="text"
                placeholder="Digite su cedula"
                className="CustomLoginInput"
                {...register("cedula", {
                  required: {
                    value: true,
                    message: "Debe ingresar un usuario",
                  },
                })}
              />

              <input
                type="password"
                placeholder="Ingrese su clave"
                className="CustomLoginInput"
                {...register("password", {
                  required: {
                    value: true,
                    message: "Debe ingresar una contraseña",
                  },
                })}
              />
            </div>

            <div id="sectionAction">
              <div className="customRadio">
                <input type="radio" name="" id="" />
                <label htmlFor="Recordarme">Recordarme</label>
              </div>

              <button className="btn-login-v2 ">
                {isSubmitting ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  "Iniciar sesión"
                )}
              </button>
            </div>
          </form>
        </article>
      </article>
    </section>
  );
};

export default SignIn;
