import React, { useEffect, useRef, useState } from "react";
import { useModalGac } from "hooks/useModalGac";
import { register } from "swiper/element/bundle";
import CustomModal from "common/CustomModal";

const SwiperPromotions = ({ news = [] }) => {
  const swiperRef = useRef(null);
  const [isOpenModal, openModal, closeModal] = useModalGac(false);
  const [new_gac, setNew_gac] = useState(null);

  useEffect(() => {
    register();
    const params = {
      breakpoints: {
        1000: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 3,
        },
        500: {
          slidesPerView: 2,
        },
      },
    };
    Object.assign(swiperRef.current, params);
    swiperRef.current.initialize();
  }, []);

  const handlePromo = (promo) => {
    setNew_gac(promo);
    openModal();
  };

  const formPromos = news
    .slice() // Para evitar mutaciones no deseadas
    .sort((a, b) => {
      // Si 'fijado' es 1, a debería ir antes que b
      if (a.fijado === true && b.fijado === false) {
        return -1;
      }
      // Si 'fijado' es 0, b debería ir antes que a si b es más reciente
      if (a.fijado === false && b.fijado === false) {
        return new Date(b.fecha_creacion) - new Date(a.fecha_creacion);
      }
      return 0;
    });

  return (
    <>
      <swiper-container init="false" ref={swiperRef} navigation>
        {formPromos?.map((promo) => (
          <swiper-slide key={promo.id}>
            <img
              src={promo.img}
              alt={promo.titulo}
              width={300}
              height={400}
              style={{
                objectFit: "cover",
                cursor: "pointer",
              }}
              onClick={() => handlePromo(promo)}
            />
          </swiper-slide>
        ))}
      </swiper-container>

      <CustomModal isOpen={isOpenModal} closeModal={closeModal}>
        <h1 className="text-center">{new_gac?.titulo}</h1>
        <img
          loading="lazy"
          src={new_gac?.img}
          alt={new_gac?.img}
          width={"100%"}
          height="200%"
          style={{ borderRadius: "10px" }}
          className="img"
        />
        <p dangerouslySetInnerHTML={{ __html: new_gac?.descripcion }}></p>
      </CustomModal>
    </>
  );
};

export default SwiperPromotions;
