import React from "react";
import StatsTickets from "components/ticket/StatsTickets";
import SwiperPromotions from "components/promotions/SwiperPromotions";
import FilesRRHH from "components/documents/FilesRRHH";

import "styles/profile.css";
import { useUserInfo } from "hooks/useUserInfo";
import { useUserStats } from "hooks/useUserStats";
import Loading from "components/load/Loading";
import { usePromotionsNews } from "hooks/usePromotionsNews";

const Profile = () => {
  const { data: user, isLoading: isUserLoading } = useUserInfo();

  const { data: userStats } = useUserStats(user?.id, {
    enabled: !!user?.id,
  });

  const { data: promotions, isLoading: isPromotionsLoading } =
    usePromotionsNews();

  if (isUserLoading) {
    return <Loading />;
  }

  return (
    <section className="container mt-4">
      <h1 className="title-section-promotions">MIS TICKETS</h1>
      <StatsTickets userTickets={userStats} />
      <h2 className="title-section-promotions">PROMOCIONES</h2>
      <SwiperPromotions news={isPromotionsLoading ? [] : promotions} />
      <h2 className="title-section-promotions">DE INTERES</h2>
      <FilesRRHH />
    </section>
  );
};

export default Profile;
