import React from "react";
import Aside from "common/Aside";
import Footer from "common/Footer";
import Navbar from "common/Navbar";
import { useAuth } from "context/AuthContext";
import "styles/layout.css";

const MainLayout = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return (
    <main className="grid-layout">
      {isAuthenticated && (
        <>
          <section className="header">
            <Navbar />
          </section>
          <section className="menu">
            <Aside />
          </section>
        </>
      )}
      <section className="content">{children}</section>
      <section className="footer">
        <Footer />
      </section>
    </main>
  );
};

export default MainLayout;
