import React, { useEffect, useState } from "react";
import Pagination from "common/Pagination";
import CustomModal from "common/CustomModal";
import { useModalGac } from "hooks/useModalGac";
import { deleteNew, getAllNews, patchNew } from "service/ServiceNews";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { UpdateFile } from "service/cloudinary";
import { ReactComponent as Edit } from "assets/icons/edit-button.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";

const ITEMS_PER_PAGE = 10; // Número de elementos por página

const OpcNews = () => {
  const [allNews, setAllNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isOpenModalDelete, openModalDelete, closeModalDelete] =
    useModalGac(false);
  const [isOpenModalEdit, openModalEdit, closeModalEdit] = useModalGac(false);
  const [itemSelect, setItemSelect] = useState(null);
  const startIndex = currentPage * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentTickets = allNews?.slice(startIndex, endIndex);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  const information = async () => {
    try {
      const response = await getAllNews();
      setAllNews(response);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadImageCloudinary = async (id_img, image) => {
    const data = new FormData();
    data.append("image", image[0]);
    data.append("upload_preset", "intranet_gac");
    data.append("cloud_name", "dhtnncgka");
    try {
      const response = await UpdateFile(id_img, data);

      const UrlImage = response.data.imageUrl;
      return {
        img: UrlImage,
        status: 200,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitItemEdit = handleSubmit(async (data) => {
    try {
      let response = "";
      if (typeof data.img === "object") {
        response = await uploadImageCloudinary(data.id_img, data.img);
      }

      if (response.status || data.img) {
        const {
          id,
          titulo,
          bajada,
          fijado,
          fecha_publicacion,
          descripcion,
          etiqueta,
          tipo,
          url,
          id_img,
        } = data;
        const payload = {
          id,
          titulo,
          bajada,
          fijado,
          fecha_publicacion,
          etiqueta,
          descripcion,
          tipo,
          url,
          img: response.img || data.img,
          id_img,
        };
        await patchNew(payload);
        toast.success("Noticia actualizada correctamente");
      }
      await information();
      closeModalEdit();
    } catch (error) {
      toast.error("No se ha podido actulizar, intentelo de nuevo");
    }
  });

  const handleDelete = async (id, id_img) => {
    try {
      await deleteNew(id, id_img);
      toast.success("Noticia eliminada correctamente");
      await information();
      closeModalDelete();
    } catch (error) {
      console.log(error);
      toast.error("Ocurrio un error, intentelo de nuevo");
    }
  };

  useEffect(() => {
    information();
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItem = (type, item) => {
    setItemSelect(item);
    switch (type) {
      case "EDIT":
        setValue("id", item.id);
        setValue("fijado", item.fijado);
        setValue("fecha_publicacion", item.fecha_publicacion);
        setValue("titulo", item.titulo);
        setValue("bajada", item.bajada);
        setValue("descripcion", item.descripcion);
        setValue("etiqueta", item.etiqueta);
        setValue("tipo", item.tipo);
        setValue("url", item.url);
        setValue("img", item.img);
        setValue("id_img", item.id_img);

        openModalEdit();
        break;
      case "DELETE":
        openModalDelete();
        break;

      default:
        break;
    }
  };

  return (
    <>
      <h1 className="text-center text-title">Listado de noticias</h1>
      <div className="table-wrapper">
        <table className="table-history">
          <thead>
            <tr>
              <th>#</th>
              <th>Imagen</th>
              <th>Categoria</th>
              <th>Titulo</th>
              <th>Descripción</th>
              <th>Tipo</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            {currentTickets?.map((news, index) => (
              <tr key={news.id}>
                <td>
                  <input
                    type="checkbox"
                    id={`${news.id} miCheckbox`}
                    className="mi-checkbox"
                  />
                  <label
                    htmlFor={`${news.id} miCheckbox`}
                    className="checkbox-label"
                  >
                    {index + 1}
                  </label>
                </td>
                <td>
                  <img
                    loading="lazy"
                    src={news.img}
                    alt={news.titulo}
                    className="img-table"
                  />
                </td>
                <td>{news.etiqueta}</td>
                <td>{news.titulo}</td>
                <td>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: news?.descripcion?.slice(0, 60),
                    }}
                  ></p>
                </td>
                <td>{news.tipo}</td>

                <td
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "5px",
                  }}
                >
                  <button
                    className="primaryBtn"
                    onClick={() => handleItem("EDIT", news)}
                  >
                    <Edit
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </button>
                  <button
                    className="deleteBtn"
                    onClick={() => handleItem("DELETE", news)}
                  >
                    <Delete
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        totalItems={allNews.length}
        itemsPerPage={ITEMS_PER_PAGE}
        onPageChange={handlePageChange}
      />
      <CustomModal isOpen={isOpenModalEdit} closeModal={closeModalEdit}>
        <form onSubmit={onSubmitItemEdit} className="formEdit">
          <h1 className="text-center text-title">Editar noticia</h1>
          <div className="mb-3 form-group">
            <label>Título</label>
            <input
              className="form-control"
              type="text"
              name="titulo"
              {...register("titulo")}
            />
          </div>
          <div className="mb-3 form-group">
            <label>Bajada (Campo noticia interna)</label>
            <input
              className="form-control"
              type="text"
              name="titulo"
              {...register("bajada")}
            />
          </div>

          <div className="mb-3 form-group">
            <label>Publicación fijada (Campo opcional)</label>
            <select
              className="form-select"
              name="fijado"
              {...register("fijado")}
            >
              <option value="false">No</option>
              <option value="true">Si</option>
            </select>
          </div>

          <div className="mb-3 form-group">
            <label>Fecha de publicación (Campo opcional)</label>
            <input
              className="form-control"
              name="fecha_publicacion"
              type="datetime-local"
              {...register("fecha_publicacion")}
            />
          </div>

          <div className="mb-3 form-group">
            <select className="form-select" {...register("etiqueta")}>
              <option value="Comunicación Interna">Comunicación Interna</option>
              <option value="Fechas especiales">Fechas especiales</option>
              <option value="Promociones">Promociones</option>
              <option value="Calidad">Calidad</option>
              <option value="Talento Humano">Talento Humano</option>
            </select>
          </div>

          <div className="mb-3 form-group">
            <select className="form-select" {...register("tipo")}>
              <option>Seleccione tipo de noticia</option>
              <option value="Pop up">Pop up</option>
              <option value="noticia interna">noticia interna </option>
            </select>
          </div>

          <div className="mb-3 form-group">
            <label>Descripción</label>
            <textarea
              className="form-control"
              type="text"
              rows={5}
              {...register("descripcion")}
            />
          </div>

          <input type="file" accept="image/*" {...register("img")} />

          <div className="mb-3 form-group">
            <label>Link</label>
            <input className="form-control" type="text" {...register("url")} />
          </div>

          <div className="centrar">
            <button className="mt-3 btn-login" type="submit">
              {isSubmitting ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  Actualizando noticia...
                </>
              ) : (
                "Actualizar"
              )}
            </button>
          </div>
        </form>
      </CustomModal>

      <CustomModal isOpen={isOpenModalDelete} closeModal={closeModalDelete}>
        <div
          className="modalComponent"
          style={{
            marginTop: "50%",
          }}
        >
          <div className="modalHeader">
            <h5 className="heading">Eliminar noticia</h5>
          </div>
          <button
            className="closeBtn"
            style={{
              marginTop: "50%",
            }}
            onClick={closeModalDelete}
          >
            x
          </button>
          <div className="modalContentComponent">
            ¿Esta seguro que desea eliminar esta noticia?
            <div className="modalActionsComponent">
              <div className="actionsContainer">
                <button
                  className="deleteBtn"
                  onClick={() => handleDelete(itemSelect.id, itemSelect.id_img)}
                >
                  Si, eliminar
                </button>
                <button className="cancelBtn" onClick={closeModalDelete}>
                  No, cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default OpcNews;
