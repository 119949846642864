import React, { useEffect } from "react";
import Loading from "components/load/Loading";
import { useUserInfo } from "hooks/useUserInfo";

import { useForm } from "react-hook-form";
import { patchChangePassword } from "service/infoUser";
import toast from "react-hot-toast";
import { useAuth } from "context/AuthContext";
import { useNavigate } from "react-router-dom";

const General = () => {
  const { data: user, isError, isLoading } = useUserInfo();
  const { register, handleSubmit, setValue } = useForm();
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setValue("id", user?.id);
  }, [setValue, user?.id]);

  if (isLoading) return <Loading />;

  if (isError)
    return <span>Ocurrio un error al consultar la informacón del usuario</span>;

  const OnSubmit = handleSubmit(async (payload) => {
    try {
      const response = await patchChangePassword(payload);
      toast.success(response);
      logout();
      navigate("/");
    } catch (error) {
      toast.error("ocurrio un error, intente más tarde");
    }
  });

  return (
    <article className="two-section">
      <section>
        <h4 className="title-section-promotions">Datos personales</h4>
        <div className="container-elemets">
          <label htmlFor="">Nombres</label>
          <input type="text" readOnly value={user.nombres} />
        </div>
        <div className="container-elemets">
          <label htmlFor="">Area</label>
          <input type="text" readOnly value={user.area} />
        </div>
        <div className="container-elemets">
          <label htmlFor="">Cargo</label>
          <input type="text" readOnly value={user.cargo} />
        </div>
        <div className="container-elemets">
          <label htmlFor="">Email</label>
          <input type="text" readOnly value={user.correo} />
        </div>
      </section>

      <form onSubmit={OnSubmit}>
        <h4 className="title-section-promotions">Cambiar contraseña</h4>
        <div className="container-elemets">
          <label htmlFor="">Nueva Contraseña</label>
          <input
            type="password"
            {...register("password", {
              required: {
                message: "Debe digitar una contraseña",
              },
            })}
          />
        </div>
        <button className="btn-login">Cambiar clave</button>
      </form>
    </article>
  );
};

export default General;
