import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import RouterApp from "./router/routes";
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "context/AuthContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./styles/SideBar.css";
import "./styles/main.css";
import { CookiesProvider } from "react-cookie";

const client = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <QueryClientProvider client={client}>
        <ReactQueryDevtools />
        <AuthProvider>
          <Toaster />
          <RouterApp />
        </AuthProvider>
      </QueryClientProvider>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
