import React, { useEffect, useState } from "react";
import StatsTickets from "components/ticket/StatsTickets";
import { GetTickets } from "service/ticket";
import HistoryTicket from "components/ticket/HistoryTicket";

const DashboardSupportTickets = () => {
  const [tickets, setTickets] = useState([]);

  const [actionSupport, setActionSupport] = useState(false);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await GetTickets();
        const ticketsFiltrados = response.filter(
          (ticket) => ticket.area === "Soporte"
        );
        setTickets(ticketsFiltrados);
      } catch (error) {}
    };
    fetchTickets();
  }, [actionSupport]);

  return (
    <section className="container">
      <StatsTickets userTickets={tickets} typeUser={"support"} />
      <HistoryTicket
        userTickets={tickets}
        typeUser={"support"}
        action={setActionSupport}
        value={actionSupport}
      />
    </section>
  );
};

export default DashboardSupportTickets;
