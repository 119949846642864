// TabContent.js
import React from "react";

const TabContent = ({ filteredNews, setData }) => {
  return (
    <section className="sectionNewsGac">
      {filteredNews.map((newGac, index) => (
        <div
          className={`image-container section${index + 1}`}
          onClick={() => setData(newGac)}
          key={newGac.id}
        >
          <img loading="lazy" src={newGac.img} alt="Imagen" />
          <div className="overlay">
            <p
              dangerouslySetInnerHTML={{
                __html: newGac.titulo,
              }}
            ></p>
          </div>
        </div>
      ))}
    </section>
  );
};

export default TabContent;
