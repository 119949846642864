import React, { useState } from "react";
import "styles/customPagination.css";

const Pagination = ({ totalItems, itemsPerPage, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    onPageChange(pageNumber);
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    const lastPage = Math.ceil(totalItems / itemsPerPage) - 1;
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
      onPageChange(currentPage + 1);
    }
  };

  const pageCount = Math.ceil(totalItems / itemsPerPage);
  const pages = [...Array(pageCount).keys()];

  const startIndex = Math.max(0, currentPage - 3);
  const endIndex = Math.min(startIndex + 4, pageCount - 1);

  return (
    <section className="sectionPagination">
      <button
        className="btnPagination"
        onClick={handlePreviousPage}
        disabled={currentPage === 0}
      >
        Anterior
      </button>
      <ul className="pagination">
        {pages.slice(startIndex, endIndex + 1).map((page) => (
          <li key={page}>
            <button
              className={`page ${currentPage === page ? "active" : ""}`}
              onClick={() => handlePageClick(page)}
            >
              {page + 1}
            </button>
          </li>
        ))}
        {pageCount > 5 && (
          <li>
            <span>...</span>
          </li>
        )}
      </ul>
      <button
        className="btnPagination"
        onClick={handleNextPage}
        disabled={currentPage === pageCount - 1}
      >
        Siguiente
      </button>
    </section>
  );
};

export default Pagination;
