import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useModalGac } from "hooks/useModalGac";
import CustomModal from "common/CustomModal";
import TabList from "./TabList";
import TabContent from "./TabContent";
import { useAllNewsDashboard } from "hooks/useAllNewsDashboard";
import Loading from "components/load/Loading";
import "styles/Tabs.css";

const INITIAL_STATE = {
  titulo: "",
  img: "",
  descripcion: "",
  etiqueta: "",
  id_img: "",
  tipo: "",
  url: "",
};

const DashboardContentGAC = () => {
  const { category } = useParams();
  const [selectedTab, setSelectedTab] = useState(
    category || "Comunicación Interna"
  );
  const [filteredNews, setFilteredNews] = useState([]);
  const [new_gac, setNew_gac] = useState(INITIAL_STATE);
  const [isOpenModal, openModal, closeModal] = useModalGac(false);
  const navigate = useNavigate();
  const { data: ListNews, isLoading } = useAllNewsDashboard();

  useEffect(() => {
    if (ListNews) {
      const filtered = ListNews.filter((item) => item.etiqueta === selectedTab);
      setFilteredNews(filtered);
    }
  }, [ListNews, selectedTab]);

  if (isLoading) return <Loading />;

  // Nueva función para ordenar las noticias primero si esta fijado, fecha de creación y si revisa fecha de publicacion
  const sortByDate = (newsList) => {
    const currentDate = new Date(); // Obtener la fecha y hora actual

    // Filtrar las noticias cuya fecha de publicación futura sea mayor que la fecha actual
    const filteredNews = newsList.filter((news) => {
      // Convertir la fecha de publicación a un objeto Date (si existe)
      const publicationDate = news.fecha_publicacion
        ? new Date(news.fecha_publicacion)
        : null;
      // Si no hay fecha de publicación o si la fecha de publicación es menor o igual que la fecha actual, mantener la noticia
      return !publicationDate || publicationDate <= currentDate;
    });

    return filteredNews.sort((a, b) => {
      // Si a tiene fijado igual a 1 y b no, a debe ir primero
      if (a.fijado === true && b.fijado !== true) {
        return -1;
      }
      // Si b tiene fijado igual a 1 y a no, b debe ir primero
      if (b.fijado === true && a.fijado !== true) {
        return 1;
      }

      // Convertir las fechas de creación a objetos Date
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);

      // Si ambas noticias tienen una fecha de publicación definida y están en el pasado, se ordenan por fecha de publicación
      if (a.fecha_publicacion && b.fecha_publicacion) {
        const publicationDateA = new Date(a.fecha_publicacion);
        const publicationDateB = new Date(b.fecha_publicacion);
        return publicationDateA - publicationDateB;
      }

      // Si alguna noticia no tiene fecha de publicación, se ordena por fecha de creación
      return dateB - dateA;
    });
  };

  const setData = (data) => {
    switch (data.tipo) {
      case "noticia interna":
        navigate(`/noticia/${data.id}`);
        break;
      default:
        if (data.url) {
          window.open(data.url, "_blank");
        }
    }
    setNew_gac(data);
    openModal();
  };

  return (
    <>
      <TabList
        tabs={[
          "Comunicación Interna",
          "Fechas especiales",
          "Promociones",
          "Calidad",
          "Talento Humano",
        ]}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />

      {filteredNews.length === 0 && (
        <h1 className="centrar mt-3">Categoría sin resultados</h1>
      )}

      <TabContent filteredNews={sortByDate(filteredNews)} setData={setData} />

      <CustomModal isOpen={isOpenModal} closeModal={closeModal}>
        <h1 className="text-center">{new_gac.titulo}</h1>
        <img
          src={new_gac.img}
          alt={new_gac.img}
          width={"100%"}
          height="200%"
          style={{ borderRadius: "10px" }}
          className="img"
        />
        <p dangerouslySetInnerHTML={{ __html: new_gac?.descripcion }}></p>
      </CustomModal>
    </>
  );
};

export default DashboardContentGAC;
