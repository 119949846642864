import React from "react";
import "styles/footer.css";
const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="footerGAC ">
      <div>
        <span className="rnt-tex">
          RNT No 127027. Grupo Alianza Colombia SAS advierte al usuario que en
          desarrollo de lo dispuesto en la Ley 679/01 y 1336/09 la explotación y
          el abuso sexual de niños, niñas y adolescentes en el país son
          sancionadas penal y administrativamente, conforme a las leyes
          vigentes. Oficina Principal Bogotá, Calle 93B # 17-25 Oficina
          309-310-311 Edificio Centro Empresarial PH.
        </span>
      </div>
      <span className="footer-text">
        © {year} | Todos los derechos reservados. Grupo Alianza Colombia
      </span>
    </footer>
  );
};

export default Footer;
