import React from "react";
import Tab from "./Tab";

const TabList = ({ tabs, selectedTab, setSelectedTab }) => {
  const handleSelectChange = (event) => {
    setSelectedTab(event.target.value);
  };
  return (
    <>
      <select
        value={selectedTab}
        onChange={handleSelectChange}
        className="tab-select"
      >
        {tabs.map((tabName) => (
          <option key={tabName} value={tabName}>
            {tabName}
          </option>
        ))}
      </select>
      <section className="tab-container">
        {tabs.map((tabName) => (
          <Tab
            key={tabName}
            tabName={tabName}
            isSelected={selectedTab === tabName}
            onClick={setSelectedTab}
          />
        ))}
      </section>
    </>
  );
};

export default TabList;
