import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Categorias from "assets/tickets/category.json";
import { ServiceCreateTicket } from "service/ticket";
import Areas from "assets/tickets/areas.json";
import toast from "react-hot-toast";
import { getUserInfo } from "service/infoUser";
import { UploadFile } from "service/cloudinary";

const CreateTicket = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();
  const [user, setUser] = useState(null);

  const OnSubmit = handleSubmit(
    async ({ area, tipo, descripcion, adjunto_ticket = null }) => {
      let urlFile = null;
      try {
        if (adjunto_ticket && adjunto_ticket.length > 0) {
          const data = new FormData();
          data.append("image", adjunto_ticket[0]);

          const resp = await UploadFile("RRHH", data);
          urlFile = resp.response.data.imageUrl;
        }
        await ServiceCreateTicket({
          usuario: user?.nombres,
          tipo,
          area,
          descripcion,
          adjunto_ticket: urlFile,
          usuario_id: user?.id,
        });
        reset();
        toast.success("Ticket creado correctamente");
      } catch (error) {
        toast.error("Ocurrió un error, intente nuevamente", error);
      }
    }
  );

  const renderOptions = () => {
    const opt = watch("area");

    switch (opt) {
      case "Soporte":
        return Categorias.Soporte.Opciones?.map((opcion) => (
          <option key={opcion.value} value={opcion.value}>
            {opcion.label}
          </option>
        ));
      case "Talento Humano":
        return Categorias.TalentoHumano.Opciones?.map((opcion) => (
          <option key={opcion.value} value={opcion.value}>
            {opcion.label}
          </option>
        ));
      default:
        return null;
    }
  };

  useEffect(() => {
    const request = async () => {
      const response = await getUserInfo();
      setUser(response);
    };
    request();
  }, []);
  return (
    <form className="" onSubmit={OnSubmit}>
      <div className="input-container">
        <label className="text-label">Área de gestión</label>
        <select
          name="area"
          className={`${
            errors.area ? "focusError custom-select" : "custom-select"
          }`}
          {...register("area", {
            required: {
              value: true,
              message: "Debe escoger un area a la cual será asignado el ticket",
            },
          })}
        >
          {Areas?.map((opcion) => (
            <option key={opcion.value} value={opcion.value}>
              {opcion.label}
            </option>
          ))}
        </select>
        {errors.area && (
          <span className="validateInput">{errors?.area.message}</span>
        )}
      </div>
      {watch("area")?.length > 0 && (
        <div className="input-container">
          <label className="text-label" htmlFor="opcion">
            Selecciona una opción:
          </label>
          <select
            name="tipo"
            className={`${
              errors.tipo ? "focusError custom-select" : "custom-select"
            }`}
            {...register("tipo", {
              required: {
                value: true,
                message: "Debe escoger un tipo de falla",
              },
            })}
          >
            {renderOptions()}
          </select>
          {errors.tipo && (
            <span className="validateInput">{errors?.tipo.message}</span>
          )}
        </div>
      )}
      {watch("area") === "Talento Humano" && (
        <div className="input-container">
          <label className="text-label">Adjunto</label>
          <input
            type="file"
            name="adjunto_ticket"
            {...register("adjunto_ticket")}
          />
        </div>
      )}

      <div className="input-container">
        <label className="text-label" htmlFor="email_field">
          Descripción
        </label>
        <textarea
          className={`${errors.descripcion ? "focusError" : "custom-textArea"}`}
          rows={5}
          name="descripcion"
          {...register("descripcion", {
            required: {
              value: true,
              message: "Ingrese una descripción de la falla",
            },
          })}
        ></textarea>
        {errors.descripcion && (
          <span className="validateInput">{errors?.descripcion.message}</span>
        )}
      </div>

      <button
        disabled={isSubmitting}
        title="Crear ticket"
        className="btn-ticket"
      >
        {isSubmitting ? (
          <span className="spinner-border spinner-border-sm"></span>
        ) : (
          <span>Enviar</span>
        )}
      </button>
    </form>
  );
};

export default CreateTicket;
