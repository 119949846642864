import { apiConfig } from "./apiConfig";

const authService = async (payload) => {
  try {
    const response = await apiConfig.post("/auth/login", payload);

    return response.data;
  } catch (error) {
    console.error("Error fetching auth:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export { authService };
