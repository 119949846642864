const validateFile = (file) => {
  const allowedFileTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/svg+xml",
    "image/bmp",
    "image/webp",
  ];
  const maxFileSize = 5 * 1024 * 1024; // 5MB en bytes

  if (!allowedFileTypes.includes(file.type)) {
    return "Tipo de archivo no permitido, solo se permiten archivos con extensión: jpeg, png, gif, svg, webp";
  }
  if (file.size > maxFileSize) {
    return "El tamaño del archivo supera los 5 MB";
  }
  return true;
};

export { validateFile };
