import { getCookie } from "utils/cookieUtils";
import { apiConfig } from "./apiConfig";

const GetTickets = async () => {
  try {
    const token = getCookie("token_auth");
    if (!token) {
      throw new Error("No token found");
    }

    const response = await apiConfig.get("/tickets", {
      headers: { Authorization: token },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching GetTickets:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const ServiceCreateTicket = async (ticket) => {
  try {
    const token = getCookie("token_auth");
    if (!token) {
      throw new Error("No token found");
    }
    const response = await apiConfig.post("/tickets/create", ticket, {
      headers: { Authorization: token },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching ServiceCreateTicket:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const ServiceUpdateTicket = async (ticket) => {
  try {
    const token = getCookie("token_auth");
    if (!token) {
      throw new Error("No token found");
    }
    const response = await apiConfig.patch(
      `/tickets/update/${ticket.id}`,
      ticket,
      {
        headers: { Authorization: token },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching ServiceUpdateTicket:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const getStatsTickets = async (userId) => {
  try {
    const token = getCookie("token_auth");
    if (!token) {
      throw new Error("No token found");
    }
    const response = await apiConfig.get(`tickets/ticket-user/${userId}`, {
      headers: { Authorization: token },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching getStatsTickets:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export {
  GetTickets,
  ServiceCreateTicket,
  ServiceUpdateTicket,
  getStatsTickets,
};
