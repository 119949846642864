import React, { useEffect, useState } from "react";
import { GetTickets } from "service/ticket";
import StatsTickets from "components/ticket/StatsTickets";
import CreateTicket from "components/ticket/CreateTicket";
import HistoryTicket from "components/ticket/HistoryTicket";
import { useUserInfo } from "hooks/useUserInfo";
import "styles/tickets.css";

const DashboardUserTickets = () => {
  const [showView, setshowView] = useState(true);
  const [tickets, setTickets] = useState([]);

  const { data: user } = useUserInfo();

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const data = await GetTickets();
        const userTickets = data.filter(
          (ticket) => ticket.usuario === user?.nombres
        );
        setTickets(userTickets);
      } catch (error) {}
    };
    fetchTickets();
  }, [user]);
  return (
    <section className="container">
      <StatsTickets userTickets={tickets} />

      <button
        className={`${showView ? "categoryTicket active" : "categoryTicket"}`}
        onClick={() => setshowView(true)}
      >
        Crear ticket
      </button>
      <button
        className={`${!showView ? "categoryTicket active" : "categoryTicket"}`}
        onClick={() => setshowView(false)}
      >
        Historial
      </button>

      {showView ? <CreateTicket /> : <HistoryTicket userTickets={tickets} />}
    </section>
  );
};

export default DashboardUserTickets;
