import Configuration from "components/user/Configuration";
import General from "components/user/General";
import React, { useState } from "react";
import "styles/configUser.css";

const ConfigProfile = () => {
  const [showView, setshowView] = useState(true);

  return (
    <section className="container p-2">
      <h1 className="title-section-promotions">AJUSTES DE CUENTA</h1>
      <button
        className={`tabConfig ${showView && "active"}`}
        onClick={() => setshowView(true)}
      >
        General
      </button>
      <button
        className={`tabConfig ${!showView && "active"}`}
        onClick={() => setshowView(false)}
      >
        Configuración
      </button>

      {showView ? <General /> : <Configuration />}
    </section>
  );
};

export default ConfigProfile;
