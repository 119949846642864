import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import SignIn from "../components/auth/SignIn";
import LayoutDashboard from "../layout/LayoutDashboard";
import MyTicket from "components/ticket/MyTicket";
import AuthWrapper from "common/AuthWrapper";
import NewId from "page/NewId";
import Index from "components/banners/Index";
import MainLayout from "layout/MainLayout";
import DashboardUserTickets from "page/DashboardUserTickets";
import DashboardSupportTickets from "page/DashboardSupportTickets";
import DashboardAdminTickets from "page/DashboardAdminTickets";
import HumanResources from "page/HumanResources";
import Profile from "page/Profile";
import Directory from "page/Directory";
import DashboardCreateNews from "page/DashboardCreateNews";
import DashboardResourcesHumans from "page/DashboardResourcesHumans";
import ConfigProfile from "page/ConfigProfile";

const RouterApp = () => {
  return (
    <BrowserRouter>
      <MainLayout>
        <Routes>
          <Route path="/" element={<SignIn />} />
          {/* Rutas protegidas */}
          <Route element={<AuthWrapper />}>
            <Route
              path="/news98606cac-9762-11ed"
              element={<DashboardCreateNews />}
            />
            <Route path="/dashboard/:category" element={<LayoutDashboard />} />
            <Route path="/directory" element={<Directory />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/noticia/:id" element={<NewId />} />
            <Route path="/add-ticket" element={<DashboardUserTickets />} />
            <Route path="/tickets" element={<DashboardSupportTickets />} />
            <Route path="/my-tickets" element={<MyTicket />} />
            <Route path="/review-tickets" element={<DashboardAdminTickets />} />
            <Route
              path="/tickets-resources-humans"
              element={<DashboardResourcesHumans />}
            />
            <Route path="/new-banner" element={<Index />} />
            <Route path="/human-resources" element={<HumanResources />} />
            <Route path="/config" element={<ConfigProfile />} />
          </Route>
        </Routes>
      </MainLayout>
    </BrowserRouter>
  );
};

export default RouterApp;
