import React, { useState, useEffect } from "react";
import Modal from "common/Modal";
import FormEdit from "./FormEdit";
import { toast } from "react-hot-toast";
import { DeleteBanner } from "service/SaveBanner";
import { ReactComponent as EditIcon } from "assets/icons/edit-button.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { DeleteFile } from "service/cloudinary";
import "styles/modal.css";

const Edit = ({ banners, getAPiBanners }) => {
  const [data, setData] = useState(banners);
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState({
    title: "",
    description: "",
  });

  const handleDeleteItem = (id, id_img) => {
    setIsOpen(!isOpen);
    setContent({
      title: "Eliminar banner",
      description: "¿Esta seguro de que quieres eliminar el banner?",
      id: id,
      id_img: id_img,
    });
  };

  const handleEdit = (id, id_img, img, url) => {
    setIsOpen(!isOpen);
    setContent({
      component: (
        <FormEdit
          getAPiBanners={getAPiBanners}
          id={id}
          id_img={id_img}
          img={img}
          url={url}
          setIsOpen={setIsOpen}
        />
      ),
    });
  };

  const deleteBanner = async (id, id_img) => {
    await DeleteBanner(id); // BD delete file
    await DeleteFile(id_img); //Cloudinary delete file
    setIsOpen(!isOpen);
    toast.success("Banner eliminado");
    await getAPiBanners();
  };

  useEffect(() => {
    setData(banners);
  }, [banners]);

  return (
    <>
      {isOpen && (
        <Modal
          title={content.title}
          content={content.description}
          setIsOpen={setIsOpen}
          action={deleteBanner}
          id={content?.id}
          id_img={content.id_img}
          children={content?.component}
        />
      )}

      <table className="table table-sm table-responsive table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">Imagen</th>
            <th scope="col">Opciones</th>
          </tr>
        </thead>
        <tbody>
          {data?.map(({ id, id_img, img, url }) => (
            <tr key={id}>
              <td>
                <img
                  src={img}
                  alt={id_img}
                  style={{
                    width: "200px",
                    borderRadius: "1em",
                    height: "70px",
                    objectFit: "contain",
                  }}
                />
              </td>
              <td>
                <button
                  className="primaryBtn"
                  onClick={() => handleEdit(id, id_img, img, url)}
                >
                  <EditIcon
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </button>
                <button
                  className="deleteBtn"
                  onClick={() => handleDeleteItem(id, id_img)}
                >
                  <DeleteIcon
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Edit;
