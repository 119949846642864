import CustomModal from "common/CustomModal";
import { useModalGac } from "hooks/useModalGac";
import React from "react";
import { NavLink } from "react-router-dom";

const FilesRRHH = () => {
  const [isOpenModal, openModal, closeModal] = useModalGac(false);
  const [isOpenModalWallpaper, openModalWallpaper, closeModalWallpaper] =
    useModalGac(false);

  const handleDownload = () => {
    const fileUrl = "/file_resources_human/Presentacion_Base.pptx";
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute(
      "download",
      "Formato presentación comunicación interna.pptx"
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <article className="files-rrhh">
      <div className="info-rrhh">
        <h1 className="title-info-rrhh"> información general </h1>
        <ul className="body-info-rrhh">
          <li>
            <NavLink to="/dashboard/Talento Humano">
              Información Talento Humano
            </NavLink>
          </li>
          <li>Promociones</li>
          <li>
            <a
              href="https://www.instagram.com/p/C5cVXbphhzq/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instructivo uso de noches Video
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/p/C2OEnPUMIyV/"
              target="_blank"
              rel="noopener noreferrer"
            >
              beneficios de GAC
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/p/Cq8W7LFshRL/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instructivo de Alianza Priority
            </a>
          </li>
          <li onClick={() => openModal()}>Cuentas bancarias</li>
          <li>Comunicados</li>
          <li onClick={() => openModalWallpaper()}>Wallpaper</li>
        </ul>
      </div>
      <div className="documents-rrhh">
        <h1 className="title-info-rrhh"> Documentos </h1>
        <ul className="body-info-rrhh">
          <li onClick={handleDownload}>
            Formato presentación comunicación interna
          </li>
        </ul>
      </div>

      <CustomModal isOpen={isOpenModal} closeModal={closeModal}>
        <img
          src="/file_resources_human/medios_de_pago.jpg"
          alt="medios de pago grupo alianza colombia"
          width={"100%"}
        />
      </CustomModal>
      <CustomModal
        isOpen={isOpenModalWallpaper}
        closeModal={closeModalWallpaper}
      >
        <img
          src="/file_resources_human/wallpaper.jpg"
          alt="medios de pago grupo alianza colombia"
          width={"100%"}
        />
      </CustomModal>
    </article>
  );
};

export default FilesRRHH;
