import EmployeeTable from "components/employee/EmployeeTable";
import React from "react";

const Directory = () => {
  return (
    <main className="container">
      <EmployeeTable />
    </main>
  );
};

export default Directory;
