import React, { useState } from "react";
import { ReactComponent as CarteleraGacSVG } from "assets/icons/CarteleraGAC.svg";
import { ReactComponent as TicketSVG } from "assets/icons/Tickets.svg";
import { ReactComponent as CrmSVG } from "assets/icons/CRM.svg";
import { ReactComponent as VicidialSVG } from "assets/icons/Vicidial.svg";
import { ReactComponent as TicketsInforme } from "assets/icons/TicketsInforme.svg";
import { ReactComponent as Folder } from "assets/icons/folder.svg";
import { ReactComponent as Profile } from "assets/icons/Perfil.svg";
import { ReactComponent as DirectoryGAC } from "assets/icons/DirectorioGAC.svg";
import { ReactComponent as RRHH } from "assets/icons/rrhh.svg";
import { ReactComponent as CreateNews } from "assets/icons/create-new.svg";
import { ReactComponent as AddBanner } from "assets/icons/folder-add.svg";
import { ReactComponent as Config } from "assets/icons/Ajustes.svg";
import { NavLink } from "react-router-dom";
import { useUserInfo } from "hooks/useUserInfo";
import "styles/aside.css";

const Aside = () => {
  const [vicidialOpen, setVicidialOpen] = useState(false);
  const { data: user } = useUserInfo();

  const toggleVicidial = () => {
    setVicidialOpen(!vicidialOpen);
  };

  return (
    <>
      <aside className="aside">
        <div className="circleCustom top"></div>
        <div className="aside-option">
          <NavLink
            to="profile"
            className={({ isActive }) =>
              `option-title ${isActive && "activeOption"}`
            }
          >
            <span>Perfil</span>
            <Profile className="icon" />
          </NavLink>
        </div>
        <div className="aside-option">
          <NavLink
            to="dashboard/Comunicación Interna"
            className={({ isActive }) =>
              `option-title ${isActive && "activeOption"}`
            }
          >
            <span>Cartelera GAC</span>
            <CarteleraGacSVG className="icon" />
          </NavLink>
        </div>
        <div className="aside-option">
          <NavLink
            to="directory"
            className={({ isActive }) =>
              `option-title ${isActive && "activeOption"}`
            }
          >
            <span>Directorio GAC</span>
            <DirectoryGAC className="icon" />
          </NavLink>
        </div>
        <div className="aside-option">
          <NavLink
            to="add-ticket"
            className={({ isActive }) =>
              `option-title ${isActive && "activeOption"}`
            }
          >
            <span>tickets</span>
            <TicketSVG className="icon" />
          </NavLink>
        </div>
        <div className="aside-option">
          <NavLink
            to="human-resources"
            className={({ isActive }) =>
              `option-title ${isActive && "activeOption"}`
            }
          >
            <span>Documentos RRHH</span>
            <Folder className="icon" />
          </NavLink>
        </div>
        <div className="aside-option">
          <div className="option-title" onClick={toggleVicidial}>
            <span>Vicidial</span>
            <VicidialSVG className="icon" />
          </div>
          {vicidialOpen && (
            <ul className="sub-options">
              <li className="option-title">
                <a
                  href="http://alianzacol-web.ccxentral.com:81/valid8.php"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Validación</span>
                </a>
              </li>
              <li className="option-title">
                <a
                  href="https://alianzacol-ast1.ccxentral.com:8089/ws "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Extensión</span>
                </a>
              </li>
              <li className="option-title">
                <a
                  href="https://alianzacol-web.ccxentral.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Ingreso</span>
                </a>
              </li>
            </ul>
          )}
        </div>
        <div className="aside-option">
          <a
            href="https://grupoalianzacolombia.net/scr/adm/ent"
            target="_blank"
            rel="noopener noreferrer"
            className="option-title"
          >
            <span>CRM</span>
            <CrmSVG className="icon" />
          </a>
        </div>
        {user?.area === "Diseño y Tecnología" &&
          user?.cargo === "COMMUNITY MANAGER" && (
            <>
              <div className="aside-option">
                <NavLink
                  to="news98606cac-9762-11ed"
                  className={({ isActive }) =>
                    `option-title ${isActive && "activeOption"}`
                  }
                >
                  <span>Crear Publicación</span>
                  <CreateNews className="icon" />
                </NavLink>
              </div>
              <div className="aside-option">
                <NavLink
                  to="new-banner"
                  className={({ isActive }) =>
                    `option-title ${isActive && "activeOption"}`
                  }
                >
                  <span>Crear Banner</span>
                  <AddBanner className="icon" />
                </NavLink>
              </div>
            </>
          )}
        {user?.area === "Talento Humano" && (
          <div className="aside-option">
            <NavLink
              to="tickets-resources-humans"
              className={({ isActive }) =>
                `option-title ${isActive && "activeOption"}`
              }
            >
              <span>Tickets RRHH</span>
              <RRHH className="icon" />
            </NavLink>
          </div>
        )}
        {user?.area === "Diseño y Tecnología" &&
          user?.cargo === "AUXILIAR DE SOPORTE TECNICO" && (
            <div className="aside-option">
              <NavLink
                to="tickets"
                className={({ isActive }) =>
                  `option-title ${isActive && "activeOption"}`
                }
              >
                <span>Consultar tickets</span>
                <TicketsInforme className="icon" />
              </NavLink>
            </div>
          )}
        {user?.area === "Diseño y Tecnología" &&
          user?.cargo === "LIDER DE DISEÑO Y TIC" && (
            <div className="aside-option">
              <NavLink
                to="review-tickets"
                className={({ isActive }) =>
                  `option-title ${isActive && "activeOption"}`
                }
              >
                <span>Tickets informe </span>
                <TicketsInforme className="icon" />
              </NavLink>
            </div>
          )}
        <div className="aside-option">
          <NavLink
            to="config"
            className={({ isActive }) =>
              `option-title ${isActive && "activeOption"}`
            }
          >
            <span>Ajustes</span>
            <Config className="icon" />
          </NavLink>
        </div>
        <div className="circleCustom bottom"></div>
      </aside>

      {/* <aside className="aside-mobil">
        <div className="aside-option-mobil">
          <NavLink
            to="profile"
            className={({ isActive }) =>
              `option-mobil-title ${isActive ? "active-mobil-option" : ""}`
            }
          >
            <span>Perfil</span>
            <Profile className="icon-mobil" />
          </NavLink>
        </div>
        <div className="aside-option-mobil">
          <NavLink
            to="dashboard/Comunicación Interna"
            className={({ isActive }) =>
              `option-mobil-title ${isActive ? "active-mobil-option" : ""}`
            }
          >
            <span>Cartelera GAC</span>
            <CarteleraGacSVG className="icon-mobil" />
          </NavLink>
        </div>
        <div className="aside-option-mobil">
          <NavLink
            to="directory"
            className={({ isActive }) =>
              `option-mobil-title ${isActive ? "active-mobil-option" : ""}`
            }
          >
            <span>Directorio GAC</span>
            <DirectoryGAC className="icon-mobil" />
          </NavLink>
        </div>

        <div className="aside-option-mobil">
          <NavLink
            to="add-ticket"
            className={({ isActive }) =>
              `option-mobil-title ${isActive ? "active-mobil-option" : ""}`
            }
          >
            <span>tickets</span>
            <TicketSVG className="icon-mobil" />
          </NavLink>
        </div>
        <div className="aside-option-mobil">
          <NavLink
            to="human-resources"
            className={({ isActive }) =>
              `option-mobil-title ${isActive ? "active-mobil-option" : ""}`
            }
          >
            <span>Documentos RRHH</span>
            <Folder className="icon-mobil" />
          </NavLink>
        </div>
        <div className="aside-option-mobil">
          <div className="option-mobil-title" onClick={toggleVicidial}>
            <span>Vicidial</span>
            <VicidialSVG className="icon-mobil" />
          </div>
          {vicidialOpen && (
            <ul className="aside-option-mobil">
              <li className="option-mobil-title">
                <a
                  href="http://alianzacol-web.ccxentral.com:81/valid8.php"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-mobil"
                >
                  <span>Validación</span>
                </a>
              </li>
              <li className="option-mobil-title">
                <a
                  href="https://alianzacol-ast1.ccxentral.com:8089/ws "
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-mobil"
                >
                  <span>Extensión</span>
                </a>
              </li>
              <li className="option-mobil-title">
                <a
                  href="https://alianzacol-web.ccxentral.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-mobil"
                >
                  <span>Ingreso</span>
                </a>
              </li>
            </ul>
          )}
        </div>
        <div className="aside-option-mobil">
          <a
            href="https://grupoalianzacolombia.net/scr/adm/ent"
            target="_blank"
            rel="noopener noreferrer"
            className="option-mobil-title"
          >
            <span>CRM</span>
            <CrmSVG className="icon-mobil" />
          </a>
        </div>
        {localStorage.getItem("tecnico") && (
          <div className="aside-option-mobil">
            <NavLink
              to="tickets"
              className={({ isActive }) =>
                `option-mobil-title ${isActive ? "active-mobil-option" : ""}`
              }
            >
              <span>Consultar tickets</span>
              <TicketSVG className="icon-mobil" />
            </NavLink>
          </div>
        )}
        {localStorage.getItem("supervisor") && (
          <div className="aside-option-mobil">
            <NavLink
              to="review-tickets"
              className={({ isActive }) =>
                `option-mobil-title ${isActive ? "active-mobil-option" : ""}`
              }
            >
              <span>Tickets informe </span>
              <TicketSVG className="icon-mobil" />
            </NavLink>
          </div>
        )}
      </aside> */}
    </>
  );
};

export default Aside;
