import axios from "axios";
const baseUrl = "https://media-upload-gac-production.up.railway.app";

const UploadFile = async (folder, image) => {
  try {
    const response = await axios.post(`${baseUrl}/upload/${folder}`, image);
    return {
      response,
      status: 200,
    };
  } catch (error) {
    console.log(error);
  }
};

const UpdateFile = async (id_img, image) => {
  try {
    const response = await axios.put(`${baseUrl}/update/${id_img}`, image);
    return response;
  } catch (error) {
    console.log(error);
  }
};

const DeleteFile = async (id_img) => {
  try {
    const response = await axios.delete(`${baseUrl}/delete/${id_img}`, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export { UploadFile, UpdateFile, DeleteFile };
