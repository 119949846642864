import React from "react";
import Loading from "components/load/Loading";
import { useAllUsers } from "hooks/useAllUser";

const EmployeeTable = () => {
  const { data: users, isLoading: isUsersLoading } = useAllUsers();

  if (isUsersLoading) {
    return <Loading />;
  }

  // Agrupar empleados por inicial del nombre
  const groupedEmployees = users.reduce((acc, employee) => {
    const initial = employee.nombres.charAt(0).toUpperCase();
    if (!acc[initial]) {
      acc[initial] = [];
    }
    acc[initial].push(employee);
    return acc;
  }, {});

  return (
    <div className="table-wrapper">
      <table className="table-history">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Cargo</th>
            <th>Área</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedEmployees).map((initial) => (
            <React.Fragment key={initial}>
              <tr style={{ backgroundColor: "rgb(70 97 166)", color: "white" }}>
                <td colSpan="5">
                  <h3>{initial}</h3>
                </td>
              </tr>
              {groupedEmployees[initial].map((employee, index) => (
                <tr key={employee.nombres + index}>
                  <td>{employee.nombres}</td>
                  <td>{employee.cargo}</td>
                  <td>{employee.area}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeTable;
