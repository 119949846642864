import React, { useEffect, useState } from "react";
import { ReactComponent as TicketOpen } from "assets/icons/TicketAbiertos.svg";
import { ReactComponent as TicketProgres } from "assets/icons/TickestProceso.svg";
import { ReactComponent as TicketClose } from "assets/icons/TickestCerrados.svg";
import { ReactComponent as TicketTotal } from "assets/icons/TickestTotal.svg";

import "styles/formTicket.css";

const ticketData = [
  {
    component: TicketOpen,
    title: "Tickets abiertos",
    color: "#c12f2f",
    count: 0,
  },
  {
    component: TicketProgres,
    title: "Tickets en proceso",
    color: "#e8bf13",
    count: 0,
  },
  {
    component: TicketClose,
    title: "Tickets cerrados",
    color: "#006837",
    count: 0,
  },
  {
    component: TicketTotal,
    title: "Tickets totales",
    color: "#2f5280",
    count: 0,
  },
];

const StatsTickets = ({ userTickets, typeUser }) => {
  const [ItemTickets, setItemTickets] = useState(ticketData);

  useEffect(() => {
    const ticketsCount = () => {
      if (userTickets) {
        const newTicketData = ItemTickets.map((ticket) => {
          switch (ticket.title) {
            case "Tickets abiertos":
              return {
                ...ticket,
                count: userTickets.filter((obj) => obj.estado === "abierto")
                  .length,
              };
            case "Tickets en proceso":
              return {
                ...ticket,
                count: userTickets.filter(
                  (obj) => obj.estado === "en ejecucion"
                ).length,
              };
            case "Tickets cerrados":
              return {
                ...ticket,
                count: userTickets.filter((obj) => obj.estado === "cerrado")
                  .length,
              };
            case "Tickets totales":
              return { ...ticket, count: userTickets.length };
            default:
              return ticket;
          }
        });
        setItemTickets(newTicketData);
      }
    };

    ticketsCount();
  }, [userTickets]);

  return (
    <section className="container-tickets">
      {ItemTickets.map((ticket, index) => (
        <article
          key={index}
          className="ticket-standar"
          style={{
            background: typeUser && ticket.color,
          }}
        >
          <div
            className="circleIcon"
            style={{
              borderColor: typeUser ? "#fff" : ticket.color,
            }}
          >
            <ticket.component
              className="iconTicket"
              style={{
                fill: typeUser ? "#fff" : ticket.color,
              }}
            />
          </div>
          <div className="textTickets">
            <h5
              style={{
                color: typeUser && "#fff",
              }}
            >
              {ticket.title}
            </h5>
            <span
              style={{
                color: typeUser ? "#fff" : ticket.color,
              }}
            >
              {ticket.count}
            </span>
          </div>
        </article>
      ))}
    </section>
  );
};

export default StatsTickets;
