import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { saveTokenToServer } from "./service/SaveTokenFirbase";
import { toast } from "react-hot-toast";

const firebaseConfig = {
  apiKey: "AIzaSyBYiGhqQhHjtKtyC-9MOZhSVGEBKUiYg8c",
  authDomain: "web-notifications-gac.firebaseapp.com",
  projectId: "web-notifications-gac",
  storageBucket: "web-notifications-gac.appspot.com",
  messagingSenderId: "931102527345",
  appId: "1:931102527345:web:1910a3cb94e10efb46b0d0",
  measurementId: "G-PCPPGPED9Z",
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging();

export const checkNotificationPermission = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BMbsw8R_W-cTqTFdtbVrbX1dmZxIXeYR8a0-bde5SdlJ9sttZQKIB00hAqEA0clwINMgVjYV0TA37udaJGF3fUA",
    });

    if (currentToken) {
      // Send the token to your server and update the UI if necessary
      sendTokenToServer(currentToken);
      console.log("currentToken", currentToken);
    } else {
      // Show permission request UI
      console.log(
        "No registration token available. Request permission to generate one."
      );
      // ...
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
    // ...
  }
};

const sendTokenToServer = (token) => {
  if (localStorage.getItem("notificationsActivated")) return;
  // Logica del servicio
  if (localStorage.getItem("name")) {
    saveTokenToServer(localStorage.getItem("name"), token, "Intranet");
    console.log("EL TOKEN SE HA ALMACENADO");
    localStorage.setItem("notificationsActivated", "1");
    toast.success("Notificaciones activadas", { duration: 5000 });
  } else {
    toast.error("Debes iniciar sesión para poder activar las notificaciones", {
      duration: 5000,
    });
  }
};
