import { useQuery } from "@tanstack/react-query";
import { getAllNews } from "service/ServiceNews";

export const useAllNewsDashboard = () => {
  return useQuery({
    queryKey: ["AllNewsDashboard"],
    queryFn: getAllNews,
    retry: 3,
    refetchInterval: 1000 * 60 * 20,
  });
};
