import React, { useEffect, useState } from "react";
import Loading from "components/load/Loading";
import { Navigate, Outlet } from "react-router-dom";
import { getCookie } from "utils/cookieUtils";

const AuthWrapper = () => {
  const [isLoading, setIsLoading] = useState(true);

  const token = getCookie("token_auth");

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return token ? <Outlet /> : <Navigate to="/" replace />;
};

export default AuthWrapper;
