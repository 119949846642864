import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { UpdateBanner } from "../../service/SaveBanner";
import { UpdateFile } from "../../service/cloudinary";

const FormEdit = ({ id, id_img, img, url, getAPiBanners, setIsOpen }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm();
  useEffect(() => {
    setValue("id", id);
    setValue("id_img", id_img);
    setValue("img", img);
    setValue("url", url);
  }, [setValue, id, id_img, img, url]);

  const onSubmit = handleSubmit(async (data) => {
    let resp = null;
    try {
      if (typeof data.img === "object") {
        const file = new FormData();
        file.append("image", data.img[0]);
        resp = await UpdateFile(data.id_img, file);
      }
      await UpdateBanner({
        img: resp?.data.imageUrl || data.img,
        id_img: resp?.data.id_img || data.id_img,
        url: data.url,
        id: data.id,
      });
      toast.success("Banner actualizado, correctamente");
      getAPiBanners();
      setIsOpen(false);
    } catch (error) {
      toast.error("Algo ocurrio, intentelo de nuevo");
      console.log(error);
    }
  });

  return (
    <form
      className="form-banner"
      style={{ boxShadow: " 0 0 transparent", textAlign: "left" }}
      onSubmit={onSubmit}
    >
      <main>
        <h2 className="text-center text-title2">
          <b>Edición de banner</b>
        </h2>
        <div className="group mt-3">
          <label htmlFor="img">Selecciona una imagen</label>
          <input
            type="file"
            name="img"
            accept="image/*"
            id="img"
            {...register("img")}
          />
        </div>
        <img
          src={img}
          alt="image_uplaod"
          className="image-fluid"
          id="image"
          style={{ height: "200px", objectFit: "contain", margin: "1em 0" }}
        />
        <div className="group mt-2">
          <label htmlFor="url">Url del banner</label>
          <input
            className="form-control"
            type="text"
            id="url"
            name="url"
            {...register("url")}
          />
        </div>
        <button disabled={isSubmitting} className="btn-create-banner mt-4">
          {isSubmitting ? (
            <span className="spinner-border spinner-border-sm"></span>
          ) : (
            "Actualizar"
          )}
        </button>
      </main>
    </form>
  );
};

export default FormEdit;
