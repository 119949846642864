import { getCookie } from "utils/cookieUtils";
import { apiConfig } from "./apiConfig";

const GetBanners = async () => {
  try {
    const token = getCookie("token_auth");
    if (!token) {
      throw new Error("No token found");
    }

    const response = await apiConfig.get("/banners", {
      headers: { Authorization: token },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching get banners:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const CreateBanner = async (payload) => {
  try {
    const token = getCookie("token_auth");
    if (!token) {
      throw new Error("No token found");
    }

    const response = await apiConfig.post("banners/create", payload, {
      headers: { Authorization: token },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching create banner:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const DeleteBanner = async (id_img) => {
  try {
    const token = getCookie("token_auth");
    if (!token) {
      throw new Error("No token found");
    }

    const response = await apiConfig.delete(`/banners/delete/${id_img}`, {
      headers: { Authorization: token },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching delete banner:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const UpdateBanner = async (payload) => {
  try {
    const token = getCookie("token_auth");
    if (!token) {
      throw new Error("No token found");
    }

    const response = await apiConfig.patch(
      `/banners/update/${payload.id}`,
      payload,
      {
        headers: { Authorization: token },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching update banner:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export { GetBanners, CreateBanner, DeleteBanner, UpdateBanner };
