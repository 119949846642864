import React, { useEffect, useState } from "react";
import { GetBanners } from "service/SaveBanner";

const Banner = () => {
  const [data, setData] = useState([]);

  const getAPiBanners = async () => {
    const resp = await GetBanners();
    setData(resp);
  };
  useEffect(() => {
    getAPiBanners();
  }, []);

  return (
    <section className="container mt-3">
      <div id="carouselBanners" className="carousel" data-bs-ride="true">
        <div className="carousel-inner ">
          {data?.map(({ img, id_img, url }, index) => (
            <div
              key={id_img}
              className={`carousel-item ${index === 0 ? "active" : ""}`}
            >
              {url ? (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <img
                    loading="lazy"
                    src={img}
                    alt={img}
                    className=" border-20 img-fluid"
                  />
                </a>
              ) : (
                <img
                  loading="lazy"
                  src={img}
                  alt={img}
                  className=" border-20 img-fluid"
                />
              )}
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselBanners"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselBanners"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
        </button>
      </div>
    </section>
  );
};

export default Banner;
