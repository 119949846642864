import React from "react";
import { useForm } from "react-hook-form";
import { UploadFile } from "../../service/cloudinary";
import { CreateBanner } from "../../service/SaveBanner";
import { toast } from "react-hot-toast";

const Create = ({ getAPiBanners }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const SendFile = async (file) => {
    const data = new FormData();
    data.append("image", file);
    try {
      const response = await UploadFile("intranet", data);
      return response;
    } catch (error) {
      console.error(error);
      toast.error("No se ha podido subir el archivo");
    }
  };

  const OnSubmit = handleSubmit(async (data) => {
    const resp = await SendFile(data.img[0]);
    if (resp?.status === 200) {
      await CreateBanner({
        img: resp.response.data.imageUrl,
        id_img: resp.response.data.id_img,
        url: data.url,
      });
      await getAPiBanners();
      reset();
      toast.success("Banner agregado correctamente");
    }
  });
  return (
    <section className="container">
      <form onSubmit={OnSubmit} className="form-banner">
        <main>
          <h2 className="text-center text-title2">
            <b>Nuevo banner</b>
          </h2>
          <div className="group">
            <label htmlFor="img">Selecciona una imagen</label>
            <input
              className={
                errors.img ? "input-error form-control" : "form-control"
              }
              type="file"
              name="img"
              accept="image/*"
              id="img"
              {...register("img", {
                required: {
                  value: true,
                  message: "Debe escoger una imagen",
                },
              })}
            />
          </div>
          {errors.img && <span className="error">{errors?.img.message}</span>}
          <div className="group mt-2">
            <label htmlFor="url">Url del banner</label>
            <input
              className="form-control"
              type="text"
              id="url"
              name="url"
              {...register("url", {
                required: {
                  message: "Ingrese una contraseña",
                },
              })}
            />
          </div>

          <button disabled={isSubmitting} className="btn-create-banner mt-4">
            {isSubmitting ? (
              <span className="spinner-border spinner-border-sm"></span>
            ) : (
              "Agregar banner"
            )}
          </button>
        </main>
      </form>
    </section>
  );
};

export default Create;
