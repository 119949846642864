import React from "react";
import "../styles/modal.css";
const Modal = ({ id, id_img, action, title, content, setIsOpen, children }) => {
  return (
    <>
      <div className="darkBG" onClick={() => setIsOpen(false)} />
      <div className="centered">
        <div className="modalComponent">
          {children ? (
            children
          ) : (
            <>
              <div className="modalHeader">
                <h5 className="heading">{title}</h5>
              </div>
              <button className="closeBtn" onClick={() => setIsOpen(false)}>
                x
              </button>
              <div className="modalContentComponent">
                {content}
                <div className="modalActionsComponent">
                  <div className="actionsContainer">
                    <button
                      className="deleteBtn"
                      onClick={() => action(id, id_img)}
                    >
                      Delete
                    </button>
                    <button
                      className="cancelBtn"
                      onClick={() => setIsOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Modal;
