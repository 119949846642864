import { createContext, useState, useEffect, useContext } from "react";
import { getCookie, removeCookie } from "utils/cookieUtils";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    () => !!getCookie("token_auth")
  );

  useEffect(() => {
    const token = getCookie("token_auth");
    setIsAuthenticated(!!token);
  }, []);

  const logout = () => {
    removeCookie("token_auth");
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, setIsAuthenticated, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
