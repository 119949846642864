import { Cookies } from "react-cookie";

const cookies = new Cookies();

const getCookie = (name) => {
  return cookies.get(name);
};

const setCookie = (name, value, options) => {
  cookies.set(name, value, options);
};

const removeCookie = (name, options) => {
  cookies.remove(name, options);
};

export { getCookie, setCookie, removeCookie };
