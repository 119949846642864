import { getCookie } from "utils/cookieUtils";
import { apiConfig } from "./apiConfig";

const getUserInfo = async () => {
  try {
    const token = getCookie("token_auth");
    if (!token) {
      throw new Error("No token found");
    }

    const response = await apiConfig.get("/auth/info-user", {
      headers: { Authorization: token },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching user info:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const patchChangePassword = async (payload) => {
  console.log(payload);
  try {
    const token = getCookie("token_auth");
    if (!token) {
      throw new Error("No token found");
    }

    const response = await apiConfig.patch(
      `/users/change-password/${payload.id}`,
      payload,
      {
        headers: { Authorization: token },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching user info:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const getAllUsers = async () => {
  try {
    const token = getCookie("token_auth");
    if (!token) {
      throw new Error("No token found");
    }

    const response = await apiConfig.get("users", {
      headers: { Authorization: token },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching getAllUsers:", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export { getUserInfo, patchChangePassword, getAllUsers };
