import axios from "axios";
import { baseUrl, baseUrlCloudinary } from "utils/baseUrl";

const apiConfig = axios.create({
  baseURL: baseUrl,
  headers: { "Content-Type": "application/json" },
});

const apiConfigCloudinary = axios.create({
  baseURL: baseUrlCloudinary,
  headers: { "Content-Type": "application/json" },
});

export { apiConfig, apiConfigCloudinary };
