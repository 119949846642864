import React from "react";
import "styles/humanResources.css";

function descargarArchivo(url) {
  // Extraer el nombre del archivo de la URL
  const nombreArchivo = url.substring(url.lastIndexOf("/") + 1);

  // Crear un elemento <a> oculto
  const link = document.createElement("a");
  link.style.display = "none";

  // Crear un objeto Blob con el contenido del archivo local
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      // Crear un objeto Blob con el contenido de la respuesta
      const blobUrl = window.URL.createObjectURL(blob);
      // Establecer la URL del objeto Blob como href del enlace
      link.href = blobUrl;
      // Establecer el nombre del archivo
      link.setAttribute("download", nombreArchivo);
      // Anexar el enlace al cuerpo del documento
      document.body.appendChild(link);
      // Simular clic en el enlace para iniciar la descarga
      link.click();
      // Eliminar el enlace del cuerpo del documento
      document.body.removeChild(link);
      // Liberar recursos del objeto Blob
      window.URL.revokeObjectURL(blobUrl);
    })
    .catch((error) => {
      console.error("Error al descargar el archivo:", error);
    });
}

const HumanResources = () => {
  const handleDescargar = (url) => {
    descargarArchivo(url);
  };
  return (
    <>
      <h1 className="text-center">Documentos recursos humanos</h1>
      <div className="app container m-2">
        <div className="pdf-container">
          <div
            className="pdf-card"
            onClick={() =>
              handleDescargar(
                "file_resources_human/AUTORIZACION_DE_LICENCIA_POR_LUTO.pdf"
              )
            }
          >
            <h2>AUTORIZACIÓN DE LICENCIA POR LUTO</h2>
            <button>Descargar PDF</button>
          </div>
          <div
            className="pdf-card"
            onClick={() =>
              handleDescargar("file_resources_human/SOLICITUD_DE_LICENCIA.pdf")
            }
          >
            <h2>SOLICITUD DE LICENCIA</h2>
            <button>Descargar PDF</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HumanResources;
