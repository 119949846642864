import React, { useEffect, useState } from "react";
import { useAuth } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MenuSgv } from "assets/icons/menu.svg";
import { useUserInfo } from "hooks/useUserInfo";
import { ReactComponent as Avatar } from "assets/icons/avatar.svg";
import Aside from "./Aside";
import "styles/navbar.css";

const Navbar = () => {
  const navigate = useNavigate();
  const { data: user, isError } = useUserInfo();
  const { logout } = useAuth();
  const [showItem, setshowItem] = useState({
    Messages: false,
    Notifications: false,
    User: false,
    MobilMenu: false,
  });

  const toggleOption = (type) => {
    switch (type) {
      case "Messages":
        setshowItem({
          Messages: !showItem.Messages,
          Notifications: false,
          User: false,
          MobilMenu: false,
        });
        break;
      case "Notifications":
        setshowItem({
          Messages: false,
          Notifications: !showItem.Notifications,
          User: false,
          MobilMenu: false,
        });
        break;
      case "User":
        setshowItem({
          Messages: false,
          Notifications: false,
          User: !showItem.User,
          MobilMenu: false,
        });
        break;
      case "MenuMobil":
        setshowItem({
          Messages: false,
          Notifications: false,
          User: false,
          MobilMenu: !showItem.MobilMenu,
        });
        break;

      default:
        break;
    }
  };

  const closeSesion = () => {
    logout();
    navigate("/");
  };

  useEffect(() => {
    if (isError) {
      logout();
      navigate("/");
    }
  }, [isError, navigate, logout]);

  return (
    <div className="navbarIntranet">
      <div className="navbar-row navbar-top">
        <div className="logo">
          <img
            src="/logo-large.png"
            alt="Grupo Alianza Colombia Logo"
            height={50}
          />
        </div>
        <div className="navbar-options">
          {/* <div
            className="navbar-option"
            onClick={() => toggleOption("Messages")}
          >
            <img src="/icons/Mensajes.svg" alt="Chat" width={30} />
            {showItem.Messages && <div className="message-box">Mensajes</div>}
          </div>
          <div
            className="navbar-option"
            onClick={() => toggleOption("Notifications")}
          >
            <img
              src="/icons/Notificaciones.svg"
              alt="Notificaciones"
              width={30}
            />
            {showItem.Notifications && (
              <div className="notification-box">Notificaciones</div>
            )}
          </div> */}
          <div className="navbar-option" onClick={() => toggleOption("User")}>
            <div className="user-info">
              <div className="text-user">
                <span>Nombre:</span>
                <span>{user?.nombres}</span>
              </div>
              <Avatar width={"60px"} height={"60px"} />
            </div>
            {showItem.User && (
              <div className="extend">
                <button onClick={closeSesion} className="btn-close-sesion">
                  Cerrar Sesión
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="navbar-row navbar-middle">
        <div className="user-name">
          Usuario:
          <p>{user?.nombres}</p>
        </div>
        <button
          className={`menu-button ${showItem.MobilMenu && "active"}`}
          onClick={() => toggleOption("MenuMobil")}
        >
          <MenuSgv className={`sgv-menu ${showItem.MobilMenu && "active"}`} />
        </button>
        {showItem.MobilMenu && (
          <div className="mobil-menu">
            <Aside />
          </div>
        )}
      </div>
      <div className="navbar-row navbar-bottom">
        <div className="user-details">
          <Avatar width={"90px"} height={"90px"} />
          <div>
            <p>Nombre</p>
            <span>{user?.nombres}</span>
          </div>
          <div>
            <p>Email</p>
            <span>{user?.correo}</span>
          </div>
          <div>
            <p>Area</p>
            <span>{user?.area}</span>
          </div>
          <div>
            <p>Cargo</p>
            <span>{user?.cargo}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
