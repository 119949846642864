import React, { useEffect, useState } from "react";
import Create from "./Create";
import Edit from "./Edit";
import "../../styles/banners.css";
import { GetBanners } from "../../service/SaveBanner";

const Index = () => {
  const [data, setData] = useState([]);

  const getAPiBanners = async () => {
    const resp = await GetBanners();
    setData(resp);
    return resp;
  };
  useEffect(() => {
    getAPiBanners();
  }, []);
  return (
    <>
      <h1 className="text-center text-title">Banners intranet</h1>
      <main className="container-banners">
        <Create getAPiBanners={getAPiBanners} />
        <Edit banners={data} getAPiBanners={getAPiBanners} />
      </main>
    </>
  );
};

export default Index;
