import React from "react";
import Banner from "components/Banner";
import { messaging } from "../firebaseConfig";
import { onMessage } from "firebase/messaging";
import { toast } from "react-hot-toast";
import DashboardContentGAC from "components/TabsGac/DashboardContentGAC";

onMessage(messaging, (payload) => {
  console.log("Nuevo notificacion en directo", payload);
  toast.custom(
    (msj) => (
      <div className="notification">
        <div className="notification-img"></div>
        <div className="container-notification">
          <div className="text-container">
            <p className="title-notificacion">{payload.notification.title}</p>
            <span className="span-new">Nueva</span>
          </div>
          <p className="body-notificacion">{payload.notification.body}</p>
          <div></div>
        </div>
      </div>
    ),
    { duration: 10000 }
  );
});

const LayoutDashboard = () => {
  return (
    <section className="section-news">
      <Banner />
      <DashboardContentGAC />
    </section>
  );
};

export default LayoutDashboard;
