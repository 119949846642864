import React, { useEffect } from "react";
import { ReactComponent as Finished } from "assets/icons/Finalizado.svg";
import { ReactComponent as DontFinished } from "assets/icons/NoFinalizado.svg";
import { ReactComponent as Change } from "assets/icons/Reasignar.svg";
import { useModalGac } from "hooks/useModalGac";
import CustomModal from "common/CustomModal";
import { useForm } from "react-hook-form";
import { ServiceUpdateTicket } from "service/ticket";
import toast from "react-hot-toast";
import { UploadFile } from "service/cloudinary";

const OptionsUserSupport = ({ ticket, action, value }) => {
  const [isOpenModal, openModal, closeModal] = useModalGac(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    setValue("id", ticket?.id);
    setValue("tipo", ticket?.tipo);
    setValue("descripcion", ticket?.descripcion);
    setValue("usuario", ticket?.usuario);
    setValue("estado", ticket?.estado);
    setValue("solucion", ticket?.solucion);
    setValue("fecha_solucion", new Date());
  }, [
    ticket?.id,
    ticket?.tipo,
    ticket?.descripcion,
    ticket?.usuario,
    ticket?.estado,
    ticket?.solucion,
    setValue,
  ]);

  const onSubmit = handleSubmit(
    async ({ id, estado, solucion, adjunto_solucion }) => {
      let urlFile = null;
      try {
        if (adjunto_solucion && adjunto_solucion.length > 0) {
          const formData = new FormData();
          formData.append("image", adjunto_solucion[0]);

          const resp = await UploadFile("RRHH", formData);
          urlFile = resp.response.data.imageUrl;
        }
        await ServiceUpdateTicket({
          id,
          estado,
          solucion,
          adjunto_solucion: urlFile,
          fecha_solucion: new Date(),
        });
        toast.success("Ticket Editado Exitosamente");
        if (typeof action === "function" && typeof value !== "undefined") {
          action(!value);
        }
      } catch (error) {
        console.log(error);
        toast.error("Ocurrio un error, intentelo nuevamente", error);
      } finally {
        closeModal();
      }
    }
  );

  const openModalHandler = () => {
    openModal();
  };

  return (
    <>
      <section className="sectionOptions">
        <Finished className="iconOption" onClick={openModalHandler} />
        <DontFinished className="iconOption" title="Opción no disponible" />
        <Change className="iconOption" title="Opción no disponible" />
      </section>
      <CustomModal isOpen={isOpenModal} closeModal={closeModal}>
        <button className="closeBtn" onClick={closeModal}>
          x
        </button>
        <section
          className="containerTicketView"
          style={{
            margin: "7% 0 8% 0",
          }}
        >
          <article className="headerTicketView">
            <img
              src="/logo-large.png"
              alt="logo Grupo Alianza Colombia"
              width={"230px"}
              height={"61px"}
            />
            <div>
              <h2 className="textMainTicketView">Ticktet</h2>
              <h2 className="textValueTicketView">{ticket?.id}</h2>
            </div>
            <div>
              <p className="textMainTicketView">Nombre:</p>
              <span className="textValueTicketView">{ticket?.usuario}</span>
            </div>
            <div>
              <p className="textMainTicketView">Estado:</p>
              <span className="textValueTicketView">{ticket?.estado}</span>
            </div>
            <div>
              <p className="textMainTicketView">Fecha de Creación:</p>
              <span className="textValueTicketView">
                {new Date(ticket?.fecha_creacion).toLocaleString()}
              </span>
            </div>
            <div>
              <p className="textMainTicketView">Fecha de Solución:</p>
              <span className="textValueTicketView">
                {new Date(ticket?.fecha_solucion).toLocaleString()}
              </span>
            </div>
            <div>
              <p className="textMainTicketView">Solución:</p>
              <span className="textValueTicketView">{ticket?.solucion}</span>
            </div>
            <div>
              <p className="textMainTicketView">Tipo:</p>
              <span className="textValueTicketView">{ticket?.tipo}</span>
            </div>
            <div>
              <p className="textMainTicketView">Descripción:</p>
              <span className="textValueTicketView">{ticket?.descripcion}</span>
            </div>
          </article>
          <hr></hr>

          <h1 className="text-center text-title">Editar Ticket</h1>
          <form onSubmit={onSubmit}>
            <label className="textMainTicketView">Modificar estado</label>
            <select className="selectSupport" {...register("estado")}>
              {[
                { value: "en ejecucion", label: "En Progreso" },
                { value: "abierto", label: "Pendiente" },
                { value: "cerrado", label: "Finalizado" },
              ].map((option) => (
                <option
                  className="optionSupport"
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
            <label className="textMainTicketView">Solución</label>
            <textarea
              className="textAreaSupport"
              rows="5"
              {...register("solucion")}
            ></textarea>
            <input
              type="file"
              name="adjunto_solucion"
              {...register("adjunto_solucion")}
            />

            <button disabled={isSubmitting} className="btn-login mt-4">
              {isSubmitting ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Enviar"
              )}
            </button>
          </form>
        </section>
      </CustomModal>
    </>
  );
};

export default OptionsUserSupport;
