import { useQuery } from "@tanstack/react-query";
import { getStatsTickets } from "service/ticket";

export const useUserStats = (userId, options = {}) => {
  return useQuery({
    queryKey: ["userStats", userId],
    queryFn: () => getStatsTickets(userId),
    enabled: !!userId, // Habilita la query solo si userId está definido
    ...options,
  });
};
