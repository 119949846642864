import { useQuery } from "@tanstack/react-query";
import { getUserInfo } from "service/infoUser";

export const useUserInfo = () => {
  return useQuery({
    queryKey: ["userInfo"],
    queryFn: getUserInfo,
    retry: 3,
    refetchInterval: 1000 * 60 * 20,
  });
};
