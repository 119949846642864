import { UploadFile } from "service/cloudinary";

export const useImageUpload = () => {
  const uploadImageCloudinary = async (image) => {
    const data = new FormData();
    data.append("image", image);
    const resp = await UploadFile("intranet", data);
    return {
      img: resp.response.data.imageUrl,
      img_id: resp.response.data.id_img,
      status: resp.status,
    };
  };

  return {
    uploadImageCloudinary,
  };
};
