import React from "react";
import "styles/customModal.css";

const CustomModal = ({ children, isOpen, closeModal }) => {
  const hanldeModalContainerClick = (e) => e.stopPropagation();
  return (
    <article className={`modalGac ${isOpen && "is-open"}`} onClick={closeModal}>
      <div className="modalGac-container" onClick={hanldeModalContainerClick}>
        {children}
      </div>
    </article>
  );
};

export default CustomModal;
