import React, { useState } from "react";
import Pagination from "common/Pagination";
import OptionsTableTickets from "./OptionsTableTickets";
import OptionsUserSupport from "./OptionsUserSupport";
import { ReactComponent as File } from "../../assets/icons/file.svg";
const ITEMS_PER_PAGE = 6; // Número de elementos por página

const HistoryTicket = ({ userTickets, typeUser, action, value }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Ordenar los tickets por estado, poniendo primero los que tienen el estado "abierto"
  const sortedTickets = [...userTickets].sort((a, b) => {
    if (a.estado === "abierto" && b.estado !== "abierto") {
      return -1;
    }
    if (a.estado !== "abierto" && b.estado === "abierto") {
      return 1;
    }
    return 0;
  });

  // Calcula el rango de tickets a mostrar en la página actual
  const startIndex = currentPage * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentTickets = sortedTickets.slice(startIndex, endIndex);

  const getClassAndTextForState = (estado) => {
    switch (estado) {
      case "en ejecucion":
        return {
          className: typeUser ? "stateProgress-support" : "stateProgress",
          text: "En Progreso",
        };
      case "cerrado":
        return {
          className: typeUser ? "stateFinish-support" : "stateFinish",
          text: "Finalizado",
        };
      case "abierto":
        return {
          className: typeUser ? "statePending-support" : "statePending",
          text: "Pendiente",
        };
      default:
        return { className: "", text: estado };
    }
  };

  const handleTicketClick = (ticket) => {
    setSelectedTicket(ticket);
  };

  return (
    <>
      <div className="table-wrapper">
        <table className="table-history">
          <thead>
            <tr>
              <th>Número</th>
              <th>Nombre</th>
              <th>Fecha de Creación</th>
              <th>Tipo</th>
              <th>Estado</th>
              <th>Adjunto</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            {currentTickets.map((ticket, index) => (
              <tr key={ticket.id} onClick={() => handleTicketClick(ticket)}>
                <td>
                  <input
                    type="checkbox"
                    id={`${ticket.id} miCheckbox`}
                    className="mi-checkbox"
                  />
                  <label
                    htmlFor={`${ticket.id} miCheckbox`}
                    className="checkbox-label"
                  >
                    {index + 1}
                  </label>
                </td>
                <td>{ticket.usuario}</td>
                <td>{new Date(ticket.fecha_creacion).toLocaleString()}</td>
                <td>{ticket.tipo}</td>
                <td>
                  <span
                    className={`stateTicket ${
                      getClassAndTextForState(ticket.estado).className
                    }`}
                  >
                    {getClassAndTextForState(ticket.estado).text}
                  </span>
                </td>
                <td>
                  {ticket.adjunto_ticket && (
                    <a
                      href={ticket.adjunto_ticket}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <File
                        style={{
                          width: "40px",
                          height: "40px",
                          fill: "var(--azul-gac)",
                        }}
                      />
                    </a>
                  )}
                </td>
                <td>
                  {typeUser ? (
                    <OptionsUserSupport
                      ticket={selectedTicket}
                      action={action}
                      value={value}
                    />
                  ) : (
                    <OptionsTableTickets ticket={selectedTicket} />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        totalItems={userTickets.length}
        itemsPerPage={ITEMS_PER_PAGE}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default HistoryTicket;
