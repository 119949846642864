// Tab.js
import React from "react";

const Tab = ({ tabName, isSelected, onClick }) => {
  return (
    <div
      className={`tab ${isSelected ? "active" : ""}`}
      onClick={() => onClick(tabName)}
    >
      {tabName}
    </div>
  );
};

export default Tab;
