import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { getNewsById } from "service/ServiceNews";
import { ReactComponent as BackButtom } from "assets/icons/back-arrows.svg";

import Loading from "components/load/Loading";
import "styles/custom-new.css";

const processContent = (htmlContent) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");

  // Encontrar la primera imagen y el segundo párrafo
  const firstImg = doc.querySelector("img:nth-of-type(1)");
  const secondP = doc.querySelector("p:nth-of-type(2)");

  // Envolver la primera imagen y el segundo párrafo en un div con clase "two-column"
  if (firstImg && secondP) {
    const wrapperDiv = document.createElement("div");
    wrapperDiv.classList.add("two-column");

    firstImg.before(wrapperDiv);
    wrapperDiv.appendChild(firstImg);
    wrapperDiv.appendChild(secondP);
  }

  return doc.body.innerHTML;
};

const NewId = () => {
  const [info, setinfo] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getNewsById(id);
        setinfo(response);
      } catch (error) {
        // Handle error
      } finally {
        // Final cleanup if necessary
      }
    };

    fetchData();
  }, [id]);

  const formDate = (d) => {
    const date = new Date(d);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const processedContent = info ? processContent(info?.descripcion) : "";

  return (
    <article className="container container-news">
      <div className="m-3 back-button" style={{ fontSize: "1.5em" }}>
        <NavLink className="back-link" onClick={() => navigate(-1)}>
          <BackButtom className="icon" />
        </NavLink>
      </div>

      {info ? (
        <>
          <h3 className="text-title title-new">{info?.titulo}</h3>
          <p className="m-3">{info?.bajada}</p>
          <hr />
          <span className="date">{formDate(info?.createdAt)}</span>
          <div className="article-container">
            <article
              className="article-content"
              dangerouslySetInnerHTML={{ __html: processedContent }}
            ></article>
          </div>
        </>
      ) : (
        <div className="centrar">
          <Loading />
        </div>
      )}
    </article>
  );
};

export default NewId;
